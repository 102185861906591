export function toDate(date: string | Date | number) {
  if (date instanceof Date || typeof date === 'number') {
    return new Date(date);
  }
  let _date = date?.trim();
  if (date?.includes('/')) {
    _date = date?.split('/').reverse().join('/');
  } else if (date?.includes(':') && (date.length === 5 || date?.length === 8)) {
    _date = `10-10-1990 ${_date}`;
  }
  return Date.parse(_date);
}
export const getStaticTime = (
  startHours = 0,
  startMinutes = 0
): Array<object> => {
  const time: Array<{ name: string; value: string }> = [];
  for (let h = startHours; h < 24; h++) {
    for (
      let m = h === startHours ? Math.round(startMinutes / 5) * 5 : 0;
      m < 60;
      m += 5
    ) {
      if (m <= 9 && h <= 9) {
        time.push({ name: `0${h}:0${m}`, value: `0${h}:0${m}` });
      } else {
        if (h <= 9) {
          time.push({ name: `0${h}:${m}`, value: `0${h}:${m}` });
        } else if (m <= 9) {
          time.push({ name: `${h}:0${m}`, value: `${h}:0${m}` });
        } else {
          time.push({ name: `${h}:${m}`, value: `${h}:${m}` });
        }
      }
    }
  }
  return time;
};

export const getStaticTimeWithSeconds = (
  startHours = 0,
  startMinutes = 0
): Array<object> => {
  const time: Array<{ name: string; value: string }> = [];
  for (let h = startHours; h < 24; h++) {
    for (
      let m = h === startHours ? Math.round(startMinutes / 5) * 5 : 0;
      m < 60;
      m += 5
    ) {
      if (m <= 9 && h <= 9) {
        time.push({ name: `0${h}:0${m}:00`, value: `0${h}:0${m}:00` });
      } else {
        if (h <= 9) {
          time.push({ name: `0${h}:${m}:00`, value: `0${h}:${m}:00` });
        } else if (m <= 9) {
          time.push({ name: `${h}:0${m}:00`, value: `${h}:0${m}:00` });
        } else {
          time.push({ name: `${h}:${m}:00`, value: `${h}:${m}:00` });
        }
      }
    }
  }
  return time;
};


export const getNextDay = (currentDate: any): Date | null => {
  if(!currentDate) return null;
  const date = new Date(currentDate);

  if (isNaN(date.getTime())) {
    return null;
  }
  date.setDate(date.getDate() + 1);
  
  return date;
}
