import { CanActivateFn } from "@angular/router";
import { inject } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { IAccessTokenPayload } from "../interfaces/auth.interface";
import { EAccessType } from "../enums/EAccessType";

type availableAccessTypes =
  | EAccessType.FAT
  | EAccessType.TFA
  | EAccessType.ConfirmResetPassword

const accessDefaultRoutesMap: Record<availableAccessTypes, string> = {
  [EAccessType.FAT]: "/",
  [EAccessType.TFA]: "/",
  [EAccessType.ConfirmResetPassword]: "/auth/confirm-reset-password",
};

export const accessTokenGuard: CanActivateFn = (route, state) => {
  const jwtHelper = inject(JwtHelperService);
  const authService = inject(AuthService);
  const router = inject(Router);


  let allowedAccessToken = route.data["allowedAccessToken"];
  let allowedTypes = route.data["allowedTypes"];
  const requireSpace = route.data["requireSpace"] as boolean;


  if (allowedAccessToken && !Array.isArray(allowedAccessToken)) {
    allowedAccessToken = [allowedAccessToken];
  }

  if (allowedTypes && !Array.isArray(allowedTypes)) {
    allowedTypes = [allowedTypes];
  }

  const token = authService.getToken();
  let parsedToken = jwtHelper.decodeToken(
    token as string
  ) as IAccessTokenPayload;

  if (!allowedAccessToken && !parsedToken) {
    return true;
  }

  if (!parsedToken) {
    parsedToken = {} as any;
  }else if (requireSpace) {
    const user = authService.getUser();

    if (!user!.activeSpace) {
      router.navigate(["/space/add"]);
      return false;
    }
  }

  if (
    Array.isArray(allowedTypes) &&
    allowedTypes.includes(parsedToken.userType) &&
    Array.isArray(allowedAccessToken) &&
    allowedAccessToken.includes(parsedToken.accessType)
  ) {
    return true;
  }

  if (!(Array.isArray(allowedTypes) && allowedTypes.includes(parsedToken.userType))) {
    // authService.clearUser();
  }

  const defaultRoute = accessDefaultRoutesMap[parsedToken.accessType as availableAccessTypes];

  if (defaultRoute && defaultRoute !== state.url) {
    router.navigate([defaultRoute], { queryParamsHandling: "merge" });
  } else {
    router.navigate(["auth/login"]);
  }
  return false;
};
