import { PAYMENT_METHOD_FULL_DATA_SHAPE } from 'src/app/chatperk-core/data-shape/payment-method.datashape';

export const PREVIEW_INVOICE_DATA_SHAPE = `
  amountDue
  amountPaid
  amountRemaining
  currency
  discountsAmount
  reason
  totalAmount
  subtotalAmount
  taxesAmount
  walletBalance
`;

export const INVOICE_PAYMENT_STATUS_DATA_SHAPE = `
  id
  paymentIntent {
    status
    clientSecret
    description
    amount {
      amount
      currency
    }
    externalRef {
      provider
      refId
    }
    lastPaymentError {
      code
      declineCode
      message
    }
    paymentMethod { 
      ${PAYMENT_METHOD_FULL_DATA_SHAPE}
    }
  }
  status
  amountDue
  currency
  invoiceNo
`;

export const VIEW_INVOICE_DATA_SHAPE = `
  id
  invoiceNo
  registeredAt
  status
  totalAmount
  currency
  invoicePdfUrl
  paymentMethod { 
    ${PAYMENT_METHOD_FULL_DATA_SHAPE}
  }
`;
