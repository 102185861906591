
<div class="step {{ currentStep?.theme }}">
  <div class="step-card  flex-col-start gap-5">
      <div class="form-path" *ngIf="!scrollableMode && showStepName">
        <h3>{{ (currentStep?.stepCustomName ? currentStep?.stepCustomName : currentStep?.stepName) ?? '' | translate}}</h3>
        <sxw-svg-icon [name]="'checked'" class="step-icon"  width="15" height="15">
        </sxw-svg-icon>
      </div>
    <div class="step-content">
      <div #contentContainer class="content-container">
        <ng-content></ng-content>
      </div>
      <div *ngIf="showControllers" class="form-buttons flex-row-end">
        <ng-container *ngIf="!scrollableMode; else scrollableControllers">
          <sxw-button type="button" label="stepper.back" class="back-button b-reverse"
                      *ngIf="currentStepIndex > 0" (onClick)="move(-1)"></sxw-button>
          <sxw-button type="button" label="stepper.next" class="next-button n-reverse"
                      *ngIf="currentStepIndex < lastStepIndex" (onClick)="move(+1)"></sxw-button>
          <sxw-button type="submit" label="stepper.submit" class="submit-button"
                      *ngIf="currentStepIndex === lastStepIndex && parent?.editMode" ></sxw-button>
        </ng-container>

        <ng-template #scrollableControllers>
          <sxw-button
            *ngIf="parent?.editMode"  
            type="submit" 
            label="stepper.submit"
            [disabled]="!parent?.validate?.() || parent?.loading ?? false"
          >
          </sxw-button>
        </ng-template>
      </div>
    </div>

  </div>
</div>
